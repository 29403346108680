export default {
    authorization: {
        roles: "auth/v1/roles",
        userRole: "auth/v1/user-roles",
        userList: "auth/v1/user-roles/for-user-list",
        roleDetail: (params: any) => `auth/v1/roles/${params.roleId}`,
        resetRole: (roleNumber: number) =>
            `auth/v1/role-authorities/${roleNumber}/reset`,
        roleAuthority: "auth/v1/role-authorities",
        groups: "auth/v1/groups",
        menus: "auth/v1/menus",
        menuLevel: "auth/v1/menus/tree-view",
        menuList: "auth/v1/menus/menu-list",
        userMenu: "auth/v1/role-authorities/user-menu",
        passwordPolicy: "auth/v1/open/password-policy",
        changePasswordWithoutToken: (userId: string) =>
            `auth/v1/users/open/${userId}/password/change`,
        changePassword: "auth/v1/users/update/password",
        resetPasswordToken: "auth/v1/users/open/reset-password/request",
        enterNewPassword: "auth/v1/users/open/reset-password/confirm",
        updateUserInfo: (userId: string) => `auth/v1/users/${userId}`,
    },
    userManage: {
        userRole: "user-manage/v1/user-roles",
        userList: "user-manage/v1/user-roles/for-user-list",
    },
    common: {
        login: "auth/v1/open/login",
        //login: "https://api-dev.hnv.vvnst.com/api/auth/v1/open/login",
        loginHOS: "auth/v1/open/login/sso/hos",
        loginHMM: "auth/v1/open/login/sso/hmm",
        refreshToken: "auth/v1/open/refresh-token",
        searchID: (businessRegistrationNumber: string) =>
            `auth/v1/users/open/${businessRegistrationNumber}`,
        changePassword: (userId: string) =>
            `auth/v1/users/open/${userId}/password`,
        companyProfile: (companyCode: string) =>
            `auth/v1/companies/${companyCode}`,
        portCodes: "common/v1/port-codes",
        portCodesV2: "common/v2/port-codes",
        nationCodes: "common/v1/nations",
        commonCodes: "common/v1/common-codes",
        recordTypes: "common/v1/record-types",
        addressBookGroups: "common/v1/address-book-groups",
        nationList: "common/v1/nations",
        addressBooks: "common/v1/address-book-details",
        updateAddressBookGroup: (data: any) =>
            `common/v1/address-book-groups/${data.emailGroupCode}`,
        updateAddressBookDetails: (data: any) =>
            `common/v1/address-book-details/${data.emailAddress}`,
        deleteAddressBookGroup: (params: any) =>
            `common/v1/address-book-groups/${params.emailGroupCode}`,
        deleteAddressDetails: (params: any) =>
            `common/v1/address-book-details/${params.emailAddress}/address-book-groups/${params.groupCode}`,
        checkMailExists: (params: any) =>
            `common/v1/address-book-details/${params.emailAddress}/existing`,

        // File
        files: "file/v2/files",
        downloadFile: (sequence: number) =>
            `file/v1/files/${sequence}/downloads`,
        uploadFileToS3: "file/v2/files/upload/s3",
        uploadFileToLocal: "file/v2/files/upload/local",
        ckUploadFileToS3: "file/v1/files/ck-upload/s3",
        ckUploadFileToLocal: "file/v1/files/ck-upload/local",
        ckUploadFileToS3V2: "file/v2/files/ck-upload/s3",
        kainosMessage: "common/v1/common-messages/open/by-group",
        owner: "common/v1/companies/owner",
        shipManager: "common/v1/companies/ship-management",
        fleet: "common/v1/address-book-groups/fleet",
        groups: "common/v1/email-groups",
        followUp: "common/v1/task-managements/follow-up",
        picUser: "auth/v1/users",
        groupDetails: "common/v1/email-group-details",
        adminConfiguration: "admin-config/v1/configs",

        // Common Code
        vesselCodes: "common/v1/vessel-codes",
        vesselParticular: "common/v1/vessel-particular/admin",
        vesselSystemMaster: "common/v1/vessel-system-master/admin",
        portCodeAdmin: "common/v1/admin/port-codes",
        report: "common/v1/reports/open/helper",
        taskManagement: "common/v1/task-managements",
        company: "common/v1/companies",
        companyCode: (companyCode: string) =>
            `common/v1/companies/${companyCode}`,
        departmentCodes: "common/v1/admin/department-codes",
        updateDepartmentCodes: (departmentCodes: string) =>
            `common/v1/admin/department-codes/${departmentCodes}`,
        suppliersV2: "common/v2/suppliers",
        boardManagement: "board-management/v1/board-managements",
    },
    ewms: {
        // DashBoard
        dashboard: {
            lastestInfo: "ewms/v1/dashboard/lastestInfoList",
            progressCount: "ewms/v1/dashboard/progressCount",
        },

        warehouseCodeList: "ewms/v1/commons/warehouse-code",
        warehouseUserInfo: "ewms/v1/commons/warehouse-user-info",
        supplyReceiptList: "ewms/v1/goods-receipts/supply-receipt",
        supplyReceiptDetail: "ewms/v1/goods-receipts/supply-receipt/detail",
        supplyReceiptDetailMultiple:
            "ewms/v1/goods-receipts/supply-receipt/detail-multiple",
        vesselMovementReceiptList:
            "ewms/v1/goods-receipts/vessel-movement-receipt",
        vesselMovementReceiptDetail:
            "ewms/v1/goods-receipts/vessel-movement-receipt/detail",
        vesselMovementReceiptDetailMultiple:
            "ewms/v1/goods-receipts/vessel-movement-receipt/detail-multiple",

        supplyReleaseList: "ewms/v1/goods-releases/supply-release",
        supplyReleaseDetail: "ewms/v1/goods-releases/supply-release/detail",
        supplyReleaseDetailMultiple:
            "ewms/v1/goods-releases/supply-release/detail-multiple",
        vesselMovementReleaseList:
            "ewms/v1/goods-releases/vessel-movement-release",
        vesselMovementReleaseDetail:
            "ewms/v1/goods-releases/vessel-movement-release/detail",
        vesselMovementReleaseDetailMultiple:
            "ewms/v1/goods-releases/vessel-movement-release/detail-multiple",
        movementPlanSupply: "ewms/v1/movement-orders/movement-plan-supply",
        movementPlanSupplyPlanList:
            "ewms/v1/movement-orders/movement-plan-supply/plan",
        movementPlanSupplyOrderList:
            "ewms/v1/movement-orders/movement-plan-supply/order",
        movementPlanSupplyOrderDetail:
            "ewms/v1/movement-orders/movement-plan-supply/order/detail",
        movementPlanSupplyOrderDetailMultiple:
            "ewms/v1/movement-orders/movement-plan-supply/order/detail-multiple",
        movementHistory: "ewms/v1/movement-orders/movement-history",
        movementHistoryDetail: "ewms/v1/goods-receipts/supply-receipt/detail",
        movementCancel: "ewms/v1/movement-orders/movement-cancel",
        history: "ewms/v1/history-infos/history",
        history2: "ewms/v1/history-infos/history2",
    },
    boardManagement: {
        notice: "board-management/v1/board-managements/by-type",
    },
};
